import {
  createBrowserRouter
} from "react-router-dom";
import Configurations from "./views/configurations/configurations";
import Dashboard from "./views/dashboard/dashboard";
import SignIn from "./views/sign-in/sign-in";
import LeftSidebar from "./views/conversation/conversation";

export default createBrowserRouter([
  {
    path: "/",
    element: <SignIn />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />
  },
  {
    path: "/configurations",
    element: <Configurations />
  },
  {
    path: "/conversation/all",
    element: <LeftSidebar />
  },
]);
