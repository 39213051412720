import React from 'react';
import { List } from '@mui/material';
import PersonListItem from './PersonListItem';


interface Person {
    id: number;
    name: string;
    date: string;
  }
  
interface PersonListProps {
  filteredPeopleList: Person[];
  selectedPerson: number | null;
  setSelectedPerson: (id: number) => void;
}

const PersonList: React.FC<PersonListProps> = ({ filteredPeopleList, selectedPerson, setSelectedPerson }) => {
  return (
    <List
      sx={{
        flex: 1,
        overflowY: "hidden", 
        scrollbarWidth: "thin", 
        scrollbarGutter: "stable",
        paddingRight: { xs: "12px", sm: "16px" }, 
        '&:hover': {
          overflowY: "auto", 
        },
      }}
    >
      {filteredPeopleList.map((person) => (
        <PersonListItem
          key={person.id}
          person={person}
          selectedPerson={selectedPerson}
          setSelectedPerson={setSelectedPerson}
        />
      ))}
    </List>
  );
};

export default PersonList;
