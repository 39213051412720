import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Modal,
  Button,
  IconButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import FilterListIcon from "@mui/icons-material/FilterList";

interface DateRangePickerProps {
  selectedDateRange: string;
  setSelectedDateRange: (value: string) => void;
  customStartDate: Dayjs | null;
  setCustomStartDate: (value: Dayjs | null) => void;
  customEndDate: Dayjs | null;
  setCustomEndDate: (value: Dayjs | null) => void;
  onApply: () => void;
  onCancel: () => void;
}

const DateRangePicker: React.FC<DateRangePickerProps> = ({
  selectedDateRange,
  setSelectedDateRange,
  customStartDate,
  setCustomStartDate,
  customEndDate,
  setCustomEndDate,
  onApply,
  onCancel,
}) => {
  const [open, setOpen] = useState(false);

  const handleDateRangeChange = (range: string) => {
    setSelectedDateRange(range);
    if (range !== "Custom") {
      setCustomStartDate(dayjs());
      setCustomEndDate(dayjs());
    }
  };

  const formatDateRange = () => {
    if (customStartDate && customEndDate) {
      return `${customStartDate.format("YYYY-MM-DD")} to ${customEndDate.format(
        "YYYY-MM-DD"
      )}`;
    }
    return "Select Date Range";
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          margin: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 0,
        }}
      >
        <TextField
          fullWidth
          sx={{ marginRight: 2 }}
          value={
            selectedDateRange === "Custom"
              ? formatDateRange()
              : selectedDateRange
          }
          onClick={() => setOpen(true)}
          variant="outlined"
        />

        {/* Filter Icon */}
        <IconButton
          onClick={() => {
            console.log("Filter icon clicked");
            // filter functionality here
          }}
        >
          <FilterListIcon />
        </IconButton>

        <Modal open={open} onClose={onCancel} disableAutoFocus>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              p: 3,
              boxShadow: 24,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" mb={2}>
              Select Date Range
            </Typography>
            <Box sx={{ display: "flex", gap: 3 }}>
              <Box
                sx={{
                  width: "40%",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                {[
                  "All Time",
                  "Today",
                  "Yesterday",
                  "Last 7 Days",
                  "Last 30 Days",
                  "Last 90 Days",
                  "Last Month",
                  "Last 180 Days",
                  "Last Year",
                  "Custom",
                ].map((range) => (
                  <Button
                    key={range}
                    variant={
                      selectedDateRange === range ? "contained" : "outlined"
                    }
                    onClick={() => handleDateRangeChange(range)}
                  >
                    {range}
                  </Button>
                ))}
              </Box>
              {selectedDateRange === "Custom" && (
                <Box
                  sx={{
                    width: "60%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <DatePicker
                    label="Start Date"
                    value={customStartDate}
                    onChange={(newValue) => setCustomStartDate(newValue)}
                  />
                  <DatePicker
                    label="End Date"
                    value={customEndDate}
                    onChange={(newValue) => setCustomEndDate(newValue)}
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 3,
                gap: 2,
              }}
            >
              <Button
                onClick={() => {
                  onCancel();
                  setOpen(false);
                }}
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onApply();
                  setOpen(false);
                }}
                variant="contained"
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </LocalizationProvider>
  );
};

export default DateRangePicker;
