import { Edit, Upload } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid2,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import type {} from "@mui/x-charts/themeAugmentation";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridToolbar,
} from "@mui/x-data-grid";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/x-tree-view/themeAugmentation";
import { useState } from "react";
import MainLayout from "../../layouts/main/main_layout";
import { MuiColorInput } from "mui-color-input";
import AskBoSvg from "../../assets/svgs/askbo-chat.svg.svg";

const rows: GridRowsProp<{
  id: Number;
  name: String;
  description: String;
  category: String;
  type: String;
  defaultValue: any;
  value: any;
  options: String;
  createdAt: Number;
  updatedAt: Number;
}> = [
  {
    id: 1,
    name: "chatbotName",
    description: "To configure the name of the chatbot",
    category: "CMS",
    type: "string",
    defaultValue: null,
    value: "AskBo",
    options: "",
    createdAt: 1433373737377,
    updatedAt: 1433373737377,
  },
  {
    id: 2,
    name: "primaryColor",
    description: "To change the primary color of the chatbot",
    category: "CMS",
    type: "color",
    defaultValue: null,
    value: "#0F4B54",
    options: "#ff6666, #006bd6",
    createdAt: 1433373737377,
    updatedAt: 1433373737377,
  },
  {
    id: 3,
    name: "secondaryColor",
    description: "To change the secondary color of the chatbot",
    category: "CMS",
    type: "color",
    defaultValue: null,
    value: "#0CE865",
    options: "#ff6666, #006bd6",
    createdAt: 1433373737377,
    updatedAt: 1433373737377,
  },
  {
    id: 4,
    name: "chatbotLogo",
    description: "To change the logo of the chatbot",
    category: "Chatbot",
    type: "image",
    defaultValue: null,
    value: "AskBo.svg",
    options: "",
    createdAt: 1433373737377,
    updatedAt: 1433373737377,
  },
];

export default function Configurations() {
  const [isAddConfigurationDialogOpen, setIsAddConfigurationDialogOpen] =
    useState<boolean>(false);
  const [selectedConfigurationForEdit, setSelectedConfigurationForEdit] =
    useState<Number | null>(null);

  const selectedConfig = rows.find(
    (x) => x.id === selectedConfigurationForEdit
  );

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      renderCell: (cell) => {
        return (
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            height={"100%"}
          >
            <Typography fontWeight={500}>{cell.formattedValue}</Typography>
          </Box>
        );
      },
    },
    { field: "description", headerName: "Description", width: 360 },
    { field: "category", headerName: "Category", width: 150 },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      editable: true,
      type: "singleSelect",
      valueOptions: ["string", "number", "datetime", "color", "duration"],
    },
    { field: "defaultValue", headerName: "Default Value", width: 150 },
    {
      field: "value",
      headerName: "Value",
      width: 150,
      editable: true,
      renderCell: (params) => {
        switch (params.row.type) {
          case "color":
            return (
              <Box
              display="flex"
              // justifyContent={"center"}
              alignItems={"center"}
              height={'100%'}>
                <Box
                  borderRadius={3}
                  height={40}
                  width={40}
                  sx={{ backgroundColor: params.value }}
                ></Box>
              </Box>
            );
            case "image":
            return (
              <Box
              display="flex"
              // justifyContent={"center"}
              alignItems={"center"}
              height={'100%'}>
                <Box
                  borderRadius={3}
                  height={40}
                  width={40}
                >
                  <img src={AskBoSvg} height={40} width={40} alt="Logo" />
                </Box>
              </Box>
            );
          default:
            return (
              <Box
                display="flex"
                // justifyContent={"center"}
                alignItems={"center"}
                height={"100%"}
              >
                <Typography fontWeight={500}>
                  {params.formattedValue}
                </Typography>
              </Box>
            );
        }
      },
    },
    { field: "options", headerName: "Options", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (row) => {
        return (
          <Box>
            <IconButton
              onClick={() => {
                setSelectedConfigurationForEdit(row.id as Number);
              }}
            >
              <Edit />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <MainLayout>
      <Box sx={{ width: "100%" }}>
        {/* cards */}
        <Box display={"flex"} justifyContent={"space-between"} mt={2} mb={2}>
          <Typography component="h2" variant="h6">
            Configurations
          </Typography>
          <Button
            variant="contained"
            onClick={() => setIsAddConfigurationDialogOpen(true)}
          >
            Add Configuration
          </Button>
        </Box>

        <DataGrid
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          rows={rows}
          columns={columns}
        />
      </Box>

      <Dialog
        onClose={() => setIsAddConfigurationDialogOpen(false)}
        open={isAddConfigurationDialogOpen}
        fullWidth
      >
        <DialogTitle>Add Configuration</DialogTitle>
        <DialogContent>
          <Grid2 container spacing={2} mt={2}>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="Name">Name</FormLabel>
                <TextField
                  name="Name"
                  placeholder="Name of the configuration"
                  type="text"
                  id="Name"
                  autoFocus
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="description">Description</FormLabel>
                <TextField
                  name="description"
                  placeholder="Description"
                  type="text"
                  id="description"
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="category">Category</FormLabel>
                <TextField
                  name="category"
                  placeholder="Category"
                  type="text"
                  id="category"
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="type">Type</FormLabel>
                <TextField
                  name="type"
                  placeholder="type"
                  type="text"
                  id="type"
                  required
                  fullWidth
                  variant="outlined"
                  select
                >
                  {["string", "number", "datetime", "color", "duration"].map(
                    (item) => (
                      <MenuItem>{item}</MenuItem>
                    )
                  )}
                </TextField>
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="defaultValue">Default Value</FormLabel>
                <TextField
                  name="defaultValue"
                  placeholder="Default Value"
                  type="text"
                  id="defaultValue"
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="value">Value</FormLabel>
                <TextField
                  name="value"
                  placeholder="Value"
                  type="text"
                  id="value"
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
            </Grid2>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="value">Options</FormLabel>
                <TextField
                  name="options"
                  placeholder="Enter comma seperated values"
                  type="text"
                  id="options"
                  required
                  fullWidth
                  variant="outlined"
                />
              </FormControl>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setIsAddConfigurationDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => setIsAddConfigurationDialogOpen(false)}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={() => setSelectedConfigurationForEdit(null)}
        open={selectedConfigurationForEdit != null}
        fullWidth
      >
        <DialogTitle>Update Configuration: {selectedConfig?.name}</DialogTitle>
        <DialogContent>
          <Grid2 container spacing={2} mt={2}>
            <Grid2 size={{ md: 12 }}>
              <FormControl fullWidth>
                <FormLabel htmlFor="value">Value</FormLabel>
                {(() => {
                  switch (selectedConfig?.type) {
                    case "color":
                      return (
                        <MuiColorInput
                          format="hex"
                          value={selectedConfig?.value}
                          onChange={() => {}}
                        />
                      );
                    case "image":
                      return (
                        <Box>
                          <Button variant="contained" startIcon={<Upload />}>
                            File upload
                          </Button>

                          <Typography variant="body2" mt={2} mb={2}>
                            Preview
                          </Typography>
                          <Box>
                            <img src={AskBoSvg} height={120} width={120} />
                          </Box>
                        </Box>
                      );
                    default:
                      return (
                        <TextField
                          name="value"
                          placeholder="Value"
                          type="text"
                          id="value"
                          required
                          fullWidth
                          variant="outlined"
                          value={selectedConfig?.value}
                        />
                      );
                  }
                })()}
              </FormControl>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setSelectedConfigurationForEdit(null)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => setSelectedConfigurationForEdit(null)}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayout>
  );
}
