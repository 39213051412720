import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import MainLayout from "../../layouts/main/main_layout";
import dayjs, { Dayjs } from "dayjs";
import SearchSection from "./SearchSection";
import DateRangePicker from "./DateRangeFilter";
import PersonList from "./PersonList";
import RightSideLayout from "./RightSideLayout";

interface Person {
  id: number;
  name: string;
  date: string;
}

const AllConversation: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedPerson, setSelectedPerson] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] =
    useState<string>("All Time");
  const [customStartDate, setCustomStartDate] = useState<Dayjs | null>(dayjs());
  const [customEndDate, setCustomEndDate] = useState<Dayjs | null>(dayjs());
  const [filteredPeopleList, setFilteredPeopleList] = useState<Person[]>([]);
  const peopleList: Person[] = [
    { id: 1, name: "John Doe", date: "2024-12-01" },
    { id: 2, name: "Jane Smith", date: "2024-12-02" },
    { id: 3, name: "Alice Johnson", date: "2024-12-03" },
    { id: 4, name: "Bob Brown", date: "2024-12-04" },
    { id: 5, name: "Chris Evans", date: "2024-12-05" },
    { id: 6, name: "Emma Watson", date: "2024-12-06" },
    { id: 7, name: "Michael Brown", date: "2024-11-15" },
    { id: 8, name: "Jessica Taylor", date: "2024-11-18" },
    { id: 9, name: "James King", date: "2024-10-25" },
    { id: 10, name: "Sarah Miller", date: "2024-11-01" },
    { id: 11, name: "David Wilson", date: "2024-11-10" },
    { id: 12, name: "Sophia Lewis", date: "2024-10-10" },
    { id: 13, name: "George Harris", date: "2024-09-20" },
    { id: 14, name: "Olivia Clark", date: "2024-09-15" },
    { id: 15, name: "Lucas Walker", date: "2024-12-01" },
    { id: 16, name: "Mason Scott", date: "2024-12-10" },
    { id: 17, name: "Lily Young", date: "2024-10-03" },
    { id: 18, name: "Benjamin Adams", date: "2024-08-15" },
    { id: 19, name: "Chloe Allen", date: "2024-07-30" },
    { id: 20, name: "Samuel Nelson", date: "2024-06-10" },
    { id: 21, name: "Zoe Carter", date: "2024-05-25" },
    { id: 22, name: "Charlotte Rodriguez", date: "2024-03-17" },
    { id: 23, name: "Amelia Green", date: "2024-02-22" },
    { id: 24, name: "Ethan Perez", date: "2024-04-11" },
    { id: 25, name: "Henry Lopez", date: "2024-01-01" },
    { id: 26, name: "Isabella Hall", date: "2024-12-15" },
    { id: 27, name: "Oliver White", date: "2024-11-05" },
    { id: 28, name: "Scarlett Baker", date: "2024-10-20" },
    { id: 29, name: "Jackson Gonzalez", date: "2024-09-30" },
    { id: 30, name: "Avery Turner", date: "2024-08-08" },
    { id: 31, name: "Sophia King", date: "2024-07-25" },
    { id: 32, name: "Elijah Miller", date: "2024-06-30" },
    { id: 33, name: "Mila Collins", date: "2024-12-25" },
    { id: 34, name: "Madison Mitchell", date: "2024-11-22" },
    { id: 35, name: "Carter Perez", date: "2024-10-15" },
    { id: 36, name: "Grace Roberts", date: "2024-09-05" },
    { id: 37, name: "Jack Stewart", date: "2024-08-25" },
    { id: 38, name: "Megan Harris", date: "2024-07-18" },
    { id: 39, name: "Owen Lee", date: "2024-06-22" },
    { id: 40, name: "Ellie Hall", date: "2024-05-10" },
    { id: 41, name: "Alexander Young", date: "2024-04-02" },
    { id: 42, name: "Harper Nelson", date: "2024-03-01" },
    { id: 43, name: "Liam Carter", date: "2024-02-12" },
    { id: 44, name: "Archer Morgan", date: "2024-01-10" },
    { id: 45, name: "Evelyn Lewis", date: "2024-12-18" },
    { id: 46, name: "Matthew Walker", date: "2024-11-28" },
    { id: 47, name: "Nathaniel Robinson", date: "2024-10-07" },
    { id: 48, name: "Aiden Cooper", date: "2024-09-22" },
    { id: 49, name: "Lily Adams", date: "2024-08-16" },
    { id: 50, name: "Ryan Foster", date: "2024-07-08" },
    { id: 51, name: "John Doe", date: "2024-11-29" },
  ];

  const getStartDate = (range: string): Dayjs => {
    switch (range) {
      case "Today":
        return dayjs().startOf("day");
      case "Yesterday":
        return dayjs().subtract(1, "day").startOf("day");
      case "Last 7 Days":
        return dayjs().subtract(7, "days").startOf("day");
      case "Last 30 Days":
        return dayjs().subtract(30, "days").startOf("day");
      case "Last 90 Days":
        return dayjs().subtract(90, "days").startOf("day");
      case "Last Month":
        return dayjs().subtract(1, "month").startOf("month");
      case "Last 180 Days":
        return dayjs().subtract(180, "days").startOf("day");
      case "Last Year":
        return dayjs().subtract(1, "year").startOf("year");
      case "Custom":
        return customStartDate || dayjs();
      default:
        return dayjs().subtract(100, "years");
    }
  };

  const applyDateFilter = () => {
    const startDate = getStartDate(selectedDateRange);
    const today = dayjs().startOf("day");
    const filteredByDate = peopleList.filter((person) => {
      const personDate = dayjs(person.date).startOf("day");

      if (selectedDateRange === "Today" || selectedDateRange === "Yesterday") {
        return personDate.isSame(startDate, "day");
      }

      return (
        personDate.isAfter(startDate, "day") &&
        personDate.isBefore(today, "day")
      );
    });

    return filteredByDate;
  };

  const applyFilters = () => {
    const filteredByDate = applyDateFilter();
    const filteredBySearch = filteredByDate.filter((person) => {
      return person.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredPeopleList(filteredBySearch);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setCustomStartDate(dayjs());
    setCustomEndDate(dayjs());
    // setSearchQuery("");
    // setFilteredPeopleList(peopleList);
  };

  useEffect(() => {
    const filteredByDate = applyDateFilter();
    const filteredBySearch = filteredByDate.filter((person) => {
      return person.name.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredPeopleList(filteredBySearch);
  }, [searchQuery]);

  useEffect(() => {
    setFilteredPeopleList(peopleList);
  }, []);

  return (
    <MainLayout>
      <Box
        sx={{
          height: "100vh",
          display: { md: "flex", sm: "block" },
          flexDirection: {
            md: "row",
            sm: "column",
          },

          gap: { md: 3, sm: 2 },
          backgroundColor: "#f4f4f4",
          padding: { md: 3, sm: 2 },
        }}
      >
        {/* left  Section  */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: {
              md: "25% ",
              sm: " 100%",
            },
            borderRight: "1px solid #ccc",
            overflow: "hidden",
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          <SearchSection
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
          <DateRangePicker
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            customStartDate={customStartDate}
            setCustomStartDate={setCustomStartDate}
            customEndDate={customEndDate}
            setCustomEndDate={setCustomEndDate}
            onApply={applyFilters}
            onCancel={handleCancel}
          />

          <Divider sx={{ mt: 2 }} />
          <PersonList
            filteredPeopleList={filteredPeopleList}
            selectedPerson={selectedPerson}
            setSelectedPerson={setSelectedPerson}
          />
        </Box>
        {/* Right Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 1,
            width: {
              md: "75%",
              sm: "100%",
            },
            overflowY: "hidden",
          }}
        >
          <RightSideLayout
            selectedPerson={selectedPerson}
            filteredPeopleList={filteredPeopleList}
          />
        </Box>
      </Box>
    </MainLayout>
  );
};

export default AllConversation;
