import React from "react";
import { Box, Typography, Divider, IconButton } from "@mui/material";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";

interface RightSideLayoutProps {
  selectedPerson: number | null;
  filteredPeopleList: { id: number; name: string; date: string }[];
}

const RightSideLayout: React.FC<RightSideLayoutProps> = ({
  selectedPerson,
  filteredPeopleList,
}) => {
  const selectedPersonDetails = filteredPeopleList.find(
    (person) => person.id === selectedPerson
  );

  return (
    <Box>
      {selectedPerson && selectedPersonDetails && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginBottom: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {selectedPersonDetails.name}
            </Typography>
            <Box sx={{ cursor: "pointer" }}>
              <MoreHorizOutlinedIcon />
            </Box>
          </Box>
          <Divider sx={{}} />

          <Box
            sx={{
              borderRadius: "10px",
              padding: 2,
              maxHeight: "calc(100vh - 100px)",
              overflowY: "hidden",
              scrollbarWidth: "thin",
              scrollbarGutter: "stable",
              paddingRight: { xs: "12px", sm: "16px" },
              "&:hover": {
                overflowY: "auto",
              },
            }}
          >
            <Typography variant="body2" sx={{ marginBottom: 1 }}>
              Hello, how can I help you today?
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RightSideLayout;
