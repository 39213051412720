import React from 'react';
import { ListItem, ListItemButton, Typography } from '@mui/material';
import dayjs from 'dayjs';

interface Person {
  id: number;
  name: string;
  date: string;
}

interface PersonListItemProps {
  person: Person;
  selectedPerson: number | null;
  setSelectedPerson: (id: number) => void;
}

const PersonListItem: React.FC<PersonListItemProps> = ({ person, selectedPerson, setSelectedPerson }) => {
  return (
    <ListItem key={person.id} sx={{ padding: '8px 0' }}>
      <ListItemButton
        onClick={() => setSelectedPerson(person.id)}
        sx={{
          backgroundColor: selectedPerson === person.id ? '#cfe9ff' : 'transparent',
          borderRadius: '5px',
          padding: '12px 20px',
          transition: 'background-color 0.3s',
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{
            fontWeight: 900,
            mr: 2,
            fontSize: '16px', 
            color: '#000000', 
          }}
        >
          {person.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: 'darkslategrey', 
            fontSize: '12px',
            ml: 'auto',
            lineHeight: 1.5,
          }}
        >
          {dayjs(person.date).format('MMM D')}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
};

export default PersonListItem;
