import React from "react";
import { TextField, Box, Typography } from "@mui/material";

interface SearchSectionProps {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

const SearchSection: React.FC<SearchSectionProps> = ({
  searchQuery,
  setSearchQuery,
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: 2,
        }}
      >
        <Typography variant="h6">Search Results (1000+)</Typography>
        <Typography
          variant="body2"
          sx={{
            cursor: "pointer",
            color: "#2685ab",
            fontWeight: "bold",
            "&:hover": {
              color: "#2685ab",
              textDecoration: "underline",
              transform: "scale(1.05)",
            },
          }}
          onClick={() => setSearchQuery("")}
        >
          Reset
        </Typography>
      </Box>

      <Box
        sx={{
          margin: 2,
          mb: 0,
        }}
      >
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          autoComplete="off" 
        />
      </Box>
    </Box>
  );
};

export default SearchSection;
