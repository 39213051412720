import { createContext, PropsWithChildren, useContext, useState } from "react";
import httpClient from "../../utils/http";

interface User {
  id:        string;
  email:     string;
  role:      string;
  createdAt: number;
  updatedAt: number;
}

interface ISession {
  authToken: string | null;
  login: (email: string, password: string) => void;
  user: User | null;
}

const SessionContext = createContext<ISession | null>(null);

export function SessionProvider({ children }: PropsWithChildren) {
  const [authToken, setAuthtoken] = useState<string | null>(null);
  const [user,setUser] = useState<User| null>(null);

  function login(email: string, password: string) {
    httpClient
      .post("/v1/admin/login", {
        email: email,
        password: password,
      })
      .then(({ data }) => {
        setAuthtoken(data.token);
      });
  }
  
  return (
    <SessionContext.Provider
      value={{
        authToken,
        login,
        user
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export default function useSession(): ISession {
  return useContext(SessionContext)!;
}
