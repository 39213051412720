import { Dashboard, PagesTwoTone } from "@mui/icons-material";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { Link, useLocation } from "react-router-dom";
import { Box, Collapse } from "@mui/material"; // Import Collapse
import { useState } from "react"; // Import useState


const mainListItems = [
  // { text: "Dashboard", icon: <Dashboard />, path: "/dashboard" },
  { text: "Configurations", icon: <PagesTwoTone />, path: "/configurations" },
  { text: "Conevrsation", icon: <PagesTwoTone />,  path: "#", hasSubItems: true, },
  // { text: 'Clients', icon: <PeopleRoundedIcon /> },
  // { text: 'Tasks', icon: <AssignmentRoundedIcon /> },
];

const secondaryListItems = [
  { text: "Settings", icon: <SettingsRoundedIcon /> },
  // { text: 'About', icon: <InfoRoundedIcon /> },
  // { text: 'Feedback', icon: <HelpRoundedIcon /> },
];

export default function MenuContent() {
  const { pathname } = useLocation();
  const [openConversation, setOpenConversation] = useState(false); // State to control dropdown visibility

  const handleConversationClick = () => {
    setOpenConversation((prev) => !prev); // Toggle dropdown visibility
  };

  return (
    <Stack sx={{ flexGrow: 1, p: 1, justifyContent: "space-between" }}>
      <List>
        {mainListItems.map((item, index) =>
          item.hasSubItems ? (
            <Box key={index}>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton onClick={handleConversationClick}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>

              <Collapse in={openConversation} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link
                    to="/conversation/all" // Path for "All Conversations"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <ListItem component="div" sx={{ pl: 4 }}>
                      <ListItemText primary="All Conversations" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </Box>
          ) : (
            <Link
              to={item.path}
              style={{ textDecoration: "none", color: "inherit" }}
              key={index}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton selected={pathname === item.path}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            </Link>
          )
        )}
      </List>

      <List>
        {secondaryListItems.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <ListItemButton>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
}
